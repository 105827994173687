<!-- Finder Pólizas de Asegurados -->

<template>
  <div class="polizas_F" v-if="schema">    
    <v-sheet :elevation="4">
      
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div style="display:flex; align-items:center">
            <div class="columna" style="width:35%;padding-right:15px">
              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.poliza.value"
                  :label="schema.ctrls.poliza.label"
                  @keypress.13="event_search('buscarPoliza')">
                </v-text-field>

                <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarPoliza')">
                  <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                </v-btn>
              </div>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.dni.value"
                  :label="schema.ctrls.dni.label"
                  @keypress.13="event_search('buscarDni')">
                </v-text-field>
                <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarDni')">
                  <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="columna" style="width:64%">
              <div class="columna" style="width:100%">                
                <div class="conflex">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.apellido1.value"
                    :label="schema.ctrls.apellido1.label">                 
                  </v-text-field>

                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.apellido2.value"
                    :label="schema.ctrls.apellido2.label">                 
                  </v-text-field>
                </div>
                <div class="conflex">
                  <v-text-field
                    width="500px"
                    v-bind="$input"
                    v-model="schema.ctrls.name.value"
                    :label="schema.ctrls.name.label">                  
                  </v-text-field>
                  <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarNombre')">
                    <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campos grid menos el de acciones -->
          <template v-slot:fields_grid="{ Sitem, Sheader }">          
            {{ get_fieldGrid(Sitem, Sheader) }}
          </template>

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import { mixinFormato } from "@/mixins/mixinFormato.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");    
  
  export default {
    mixins: [mixinFinder, mixinFormato],
    components: { base_Header, base_Fgrid, btracombi },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:null,
        api:'polizasMapfre_F',
        stName:'stFpolizasMapfre',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
     
        headers: {},
        ST_zonas: []        
      };
    },
    
    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Busqueda de Asegurados MAPFRE";  

        this.headers = {
          header: [
            { text: "Poliza", value: "poliza", witdh: "10rem" },
            { text: "Asegurado", value: "fallename", witdh: "10rem" },
            { text: "Dni", value: "falledni", witdh: "10rem" },
            { text: "Edad", value: "falleedad", witdh: "10rem" },
            { text: "Localidad", value: "LUGAR", witdh: "10rem" },
            { text: "Recibo Vto.", value: "RECIBO_VTO", witdh: "10rem" },
            { text: "Estado", value: "RECIBO_STD", witdh: "10rem" },  
            { text: "Garantia", value: "servpm", witdh: "10rem" },         
            { text: "Acciones", value: "acciones", sortable: false, width: "auto" }
          ],
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'funes_M';

        // obtengo items zonas 
        this.ST_zonas = this.$store.state.datos_iniciales.zonas;
        this.ST_zonas.unshift({ id: "0", zona: "Todas" });
      },   


      //
      ini_schemaCtrls() {
        if (!this.padre) return; 
        
        this.schema.ctrls.poliza.value= this.$store.state[this.padre].schema.ctrls.poliza.value;
      },


      // Recoge eventos de busqueda evt = buscarPoliza,Dni,Nombre
      async event_search(evt) {
        var ctrls= this.schema.ctrls;

        let m='';
        if (evt=='buscarPoliza' && ctrls.poliza.value=='') m='Debes introducir Poliza';
        if (evt=='buscarDni' && ctrls.dni.value=='') m='Debes introducir DNI';
        if (evt=='buscarNombre' && ctrls.apellido1.value=='') m='Debes introducir Apellidos, Nombre';
        if (m>'') {          
          this.$root.$alert.open(m, 'error');
          return;
        }

        var args= { tipo:'fnc', accion: 'expediente', 
          fn_args: { accion:'poliza', opc:1, 
            args: {
              pol: (evt=='buscarPoliza' ? ctrls.poliza.value : ''), 
              dni: (evt=='buscarDni' ? ctrls.dni.value : ''),               
              ap1: (evt=='buscarNombre' ? ctrls.apellido1.value : ''), 
              ap2: (evt=='buscarNombre' ? ctrls.apellido2.value : ''), 
              nom: (evt=='buscarNombre' ? ctrls.name.value : ''),
            }
          }
        };

        this.buscarExecFnc(args);       
      },


      // configuración particular campos del grid
      get_fieldGrid(item, header) {
        switch (header.value) {
          case "RECIBO_VTO":
            return this.sql2visual({ format: 'fh'}, item[header.value]);

          default:
            return item[header.value];
        }
      }

      
    }
  }
</script>


